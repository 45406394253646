<template>
  <main>
    <SectionHero variant="transparent-fixed" class="z-10 -md:!min-h-[calc(100svh_+_1px)] hero-main">
      <Hero :home="home" />
    </SectionHero>
    <SectionDefault>
      <Builder :blocks="blocks" :context="{ titleAnimation: 'rotation' }" />
    </SectionDefault>
  </main>
</template>

<script>
import Builder from '../components/builder/Builder'
import pageBuilder from '../components/mixins/pageBuilder.js'
import Hero from '../components/molecules/Hero'
import SectionDefault from '../components/templates/layouts/SectionDefault'
import SectionHero from '../components/templates/layouts/SectionHero'

const mixinPageBuilder = pageBuilder({
  pageUrl: 'index',
  async fetch({ store }) {
    const id = `home`
    try {
      return {
        home: store.state.modules.content[id] || (await store.dispatch('modules/content/getContent', { id })) || {},
      }
    } catch (e) {
      return {}
    }
  },
})

export default {
  name: 'PageMain',
  components: {
    Builder,
    Hero,
    SectionDefault,
    SectionHero,
  },
  mixins: [mixinPageBuilder],
  data() {
    return {
      home: {},
    }
  },
}
</script>
