<template>
  <section
    class="relative w-full min-h-[calc(83svh_+_1px)] md:min-h-[calc(100svh_+_1px)] print:min-h-0 sd bg-white"
    :class="{ [`variant-${variant}`]: true, [`z-${z}`]: true }"
    data-scroll-id="hero"
    data-scroll
    data-scroll-repeat
    data-scroll-section
    data-resource-loader-context
  >
    <div :class="{ 'fixed top-0': isFixed }" data-scroll data-scroll-id="header_pin">
      <div ref="pin">
        <div
          class="hero-wrap md:pr-5 move-image"
          :class="{
            'border-before md:pl-[75px]': variant === 'dark-parallax' || variant === 'white-parallax',
            'md:pl-[75px]': variant === 'transparent-parallax' || variant === 'transparent-fixed',
            'move-top': shouldMoveImage,
          }"
        >
          <slot />
          <div
            v-if="$slots.footer"
            class="absolute z-10 -md:left-3 -md:right-3 bottom-0 right-0 md:left-[75px] md:h-[110px] print:static print:inline-block -md:bottom-3"
          >
            <slot name="footer" />
          </div>
          <div
            v-if="$slots.footerShort"
            class="absolute z-10 bottom-3 md:bottom-0 left-3 right-3 md:left-[75px] md:right-5 md:pl-5 md:h-[110px]"
          >
            <div class="-md:flex flex-col md:grid grid-cols-12 h-full pb-4 md:pb-6">
              <div class="md:col-span-10 md:col-start-3 flex flex-1">
                <slot name="footerShort" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="absolute w-px inset-y-0 bg-white left-[75px] hidden md:block z-10"
      :class="{ '!w-0': variant === 'dark-parallax' || variant === 'white-parallax' }"
    />
  </section>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'dark-parallax', // transparent-fixed transparent-parallax
    },
    z: {
      type: String,
      default: '20',
    },
  },
  data() {
    return {
      isFixed: false,
      shouldMoveImage: false,
    }
  },
  computed: {
    pageEntering() {
      return this.$store.getters['pageTransition/entering']
    },
    hasPreloadScreen() {
      return this.$store.getters['entryLoaderScreen/hasPreloadScreen']
    },
  },
  watch: {
    hasPreloadScreen() {
      this.shouldMoveImage = true
    },
    pageEntering() {
      this.shouldMoveImage = this.pageEntering
    },
  },
  mounted() {
    this.isFixed = this.variant === 'transparent-fixed' && !this.isDesktop()
    this.$root.$loco.on('scroll', this.handleScroll)
  },
  beforeDestroy() {
    this.$root.$loco.off('scroll', this.handleScroll)
  },
  methods: {
    desktopSetFixed(data) {
      const pin = data.currentElements.header_pin
      if (pin && this.$refs.pin) {
        const box = pin.el.getBoundingClientRect()
        const transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${-box.y.toFixed(2)}, 0, 1)`
        this.$refs.pin.style.transform = box.y < 0 ? transform : 'none'
      }
    },
    isDesktop() {
      return Boolean(this.$root.$loco && !this.$root.$loco.scroll.isMobile && !this.$root.$loco.scroll.isTablet)
    },
    handleScroll(data) {
      if (this.isDesktop() && this.variant === 'transparent-fixed') {
        this.desktopSetFixed(data)
      }
    },
  },
}
</script>

<style lang="postcss">
.variant-transparent-fixed > div {
  opacity: 0;
}
.variant-transparent-fixed.is-inview > div {
  opacity: 1;
}
.move-image img {
  transform: translateY(50px);
}
.move-image.move-top img {
  transition: transform 1s var(--cubic-bezier);
  transform: translateY(0);
}
.border-before {
  &:before {
    @apply w-[75px] h-full hidden md:block absolute left-0 bg-gray-100;
    content: '';
  }
}
</style>
