<template>
  <div class="flex flex-1 min-h-screen">
    <AtomImage
      class="absolute inset-0 object-cover object-center min-h-full w-full"
      :strapi-image-object="$device.isDesktop ? home.heroImage : home.heroImageMobile"
    />
    <h1
      class="container relative pt-14 pb-7 md:pt-8 md:pb-6 text-white uppercase min-h-min md:self-end flex flex-col md:justify-end py-0"
    >
      <span>
        <template v-for="(stroke, a) in strokes">
          <template v-for="(part, b) in stroke">
            <component :is="part.is" v-bind="part.props" :key="`${a}_${b}`" />
          </template>
        </template>
      </span>
    </h1>
  </div>
</template>

<script>
import AtomImage from '../atoms/AtomImage'
import AtomTitle from '../atoms/AtomTitle'
import HeroVideo from './HeroVideo'

export default {
  name: 'MoleculeHero',
  components: {
    AtomTitle,
    AtomImage,
    HeroVideo,
  },
  props: ['home'],
  computed: {
    strokes() {
      const videoSource = (this.home.heroVideo || {}).url
      return (this.home.text || '').split(/\n+/).map((stroke, i) => {
        const reVideo = /\[video\]/i
        const hasVideo = reVideo.test(stroke)
        if (!hasVideo) return [getTitle(stroke, i * 100)]
        const SEPARATOR = '***'
        return stroke
          .replace(reVideo, () => `${SEPARATOR}${JSON.stringify(getVideo())}${SEPARATOR}`)
          .split(SEPARATOR)
          .map(part => {
            if (!part) return null
            try {
              return JSON.parse(part)
            } catch (e) {
              return getTitle(part, i * 100)
            }
          })
          .filter(Boolean)
      })
      function getTitle(text, delay) {
        return {
          is: AtomTitle.name,
          props: {
            tag: 'div',
            animation: 'rotation',
            delay: delay + '',
            class: 'relative inline-block leading-[85%] z-10',
            content: text,
            offsetZero: true,
          },
        }
      }
      function getVideo() {
        if (!videoSource) return '&nbsp;'
        return {
          is: HeroVideo.name,
          props: {
            source: videoSource,
          },
        }
      }
    },
  },
}
</script>
