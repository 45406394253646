<template>
  <span
    ref="holder"
    class="video-holder relative inline-block w-[8vw] h-[vw] -top-1 md:-top-3 md:w-[15vw] md:h-[7vw] rounded-[5vw] overflow-hidden"
  >
    <span
      v-if="$device.isDesktop"
      ref="video"
      :data-bobo="$device.isDesktop"
      class="hidden md:block absolute overflow-hidden"
    >
      <AtomVideo ref="atomVideo" :src="source" :fit="true" />
    </span>
    <video
      v-else-if="playing"
      ref="mobileVideo"
      :src="source"
      autoplay
      loop
      muted
      class="w-0 h-0"
      @loadedmetadata="handleLoad"
      @webkitendfullscreen="onExitFullscreen"
    ></video>
    <svg
      class="w-full h-full md:hidden"
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="playVideo"
    >
      <mask id="path-1-inside-1_1330_7356" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29ZM19.424 13.4624C20.0507 13.854 20.0507 14.7667 19.424 15.1583L12.53 19.4671C11.864 19.8834 11 19.4045 11 18.6191V10.0016C11 9.21616 11.8639 8.73732 12.53 9.1536L19.424 13.4624Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29ZM19.424 13.4624C20.0507 13.854 20.0507 14.7667 19.424 15.1583L12.53 19.4671C11.864 19.8834 11 19.4045 11 18.6191V10.0016C11 9.21616 11.8639 8.73732 12.53 9.1536L19.424 13.4624Z"
        fill="white"
      />
      <path
        d="M19.424 15.1583L19.954 16.0063L19.424 15.1583ZM19.424 13.4624L18.894 14.3104L19.424 13.4624ZM12.53 19.4671L12 18.6191H12L12.53 19.4671ZM12.53 9.1536L13.06 8.3056L12.53 9.1536ZM28 14.5C28 21.9558 21.9558 28 14.5 28V30C23.0604 30 30 23.0604 30 14.5H28ZM14.5 1C21.9558 1 28 7.04416 28 14.5H30C30 5.93959 23.0604 -1 14.5 -1V1ZM1 14.5C1 7.04416 7.04416 1 14.5 1V-1C5.93959 -1 -1 5.93959 -1 14.5H1ZM14.5 28C7.04416 28 1 21.9558 1 14.5H-1C-1 23.0604 5.93959 30 14.5 30V28ZM19.954 16.0063C21.2073 15.223 21.2073 13.3977 19.954 12.6144L18.894 14.3104L18.894 14.3104L19.954 16.0063ZM13.06 20.3151L19.954 16.0063L18.894 14.3103L12 18.6191L13.06 20.3151ZM10 18.6191C10 20.19 11.7279 21.1477 13.06 20.3151L12 18.6191L12 18.6191H10ZM10 10.0016V18.6191H12V10.0016H10ZM13.06 8.3056C11.7279 7.47304 10 8.43073 10 10.0016H12H12L13.06 8.3056ZM19.954 12.6144L13.06 8.3056L12 10.0016L18.894 14.3104L19.954 12.6144Z"
        fill="white"
        mask="url(#path-1-inside-1_1330_7356)"
      />
    </svg>
    <span
      class="video-area hidden md:inline absolute inset-0 border-2 rounded-[5vw]"
      @mouseenter="handleVideoEnter"
      @mouseleave="handleVideoLeave"
    />
  </span>
</template>

<script>
import AtomVideo from '../atoms/AtomVideo'

export default {
  name: 'HeroVideo',
  components: {
    AtomVideo,
  },
  props: ['source'],
  data() {
    return {
      playing: false,
    }
  },
  mounted() {
    this.videoRecalcPosition()
    window.addEventListener('resize', this.videoRecalcPosition)
    this.interval = setInterval(() => {
      if (!this.$refs.video) return
      const box = this.$refs.video.getBoundingClientRect()
      if (box.x !== 0) this.videoRecalcPosition()
    }, 150)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.videoRecalcPosition)
    clearInterval(this.interval)
    document.removeEventListener('fullscreenchange', this.handleFullscreen, false)
    document.removeEventListener('webkitfullscreenchange', this.handleFullscreen, false)
    document.removeEventListener('mozfullscreenchange', this.handleFullscreen, false)
  },
  methods: {
    playVideo() {
      if (!this.$device.isDesktop) {
        this.playing = true
      }
    },
    handleLoad(event) {
      const video = event.target
      video.currentTime = this.currentTime || 0
      video.play()
      video.webkitEnterFullscreen()
      document.addEventListener('fullscreenchange', this.handleFullscreen, false)
      document.addEventListener('webkitfullscreenchange', this.handleFullscreen, false)
      document.addEventListener('mozfullscreenchange', this.handleFullscreen, false)
    },
    handleFullscreen() {
      const element = document.fullScreenElement || document.webkitFullscreenElement || document.mozFullscreenElement
      if (!element) {
        this.onExitFullscreen()
      }
    },
    onExitFullscreen() {
      this.currentTime = this.$refs.mobileVideo.currentTime
      this.playing = false
      document.removeEventListener('fullscreenchange', this.handleFullscreen, false)
      document.removeEventListener('webkitfullscreenchange', this.handleFullscreen, false)
      document.removeEventListener('mozfullscreenchange', this.handleFullscreen, false)
    },
    handleVideoEnter() {
      this.$refs.holder.classList.add('video-show')
    },
    handleVideoLeave() {
      this.$refs.holder.classList.remove('video-show')
    },
    videoRecalcPosition() {
      if (!this.$refs.video) return
      const box = this.$refs.holder.getBoundingClientRect()
      const BORDER_WIDTH = 0
      this.$refs.video.style.cssText = [
        `top: ${-box.top - BORDER_WIDTH}px;`,
        `left: ${-box.left - BORDER_WIDTH}px;`,
        `bottom: ${box.bottom - window.innerHeight - BORDER_WIDTH}px;`,
        `right: ${box.right - window.innerWidth - BORDER_WIDTH}px;`,
      ].join('')
    },
  },
}
</script>

<style lang="postcss" scoped>
.video-holder {
  .video-area {
    transition: border-color 0.5s var(--cubic-bezier);
    @apply border-transparent;
  }
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.video-holder.video-show {
  @apply overflow-visible;
  -webkit-mask-image: none;
  .video-area {
    @apply border-white;
  }
}
</style>
